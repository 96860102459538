import React, { Fragment, useRef, useState } from 'react'
import { ReactComponent as IconQuestion } from '../../images/icons/question.svg'
import { Modal } from 'antd'
import Markdown from '../Markdown'

const help = ({ text }) => {
  let [isActive, setIsActive] = useState(false)
  let btnOk = useRef(null)
  let changeActive = (e) => {
    if (e) {
      e.preventDefault()
    }
    setIsActive(!isActive)
    setTimeout(() => {
      if (btnOk) {
        btnOk.current.focus()
      }
    })
  }
  return (
    <Fragment>
      <span className={'helpForLabel'} onClick={changeActive}><IconQuestion
        style={{
          width: '0.75rem',
          height: '0.75rem',
          margin: '0px 0.25rem 0.35rem 0.25rem',
        }}/></span>
      <Modal
        visible={isActive}
        onCancel={changeActive}
        closable={false}
        width={410}
        footer={false}>
        <div className={'m-3'}><Markdown>{text}</Markdown>
          <div className={'w-100'}>
            <button className={'btn btn-secondary dart-btn mt-3'}
                    ref={btnOk}
                    style={{ width: 140, marginLeft: 'calc(50% - 70px)' }}
                    onClick={changeActive}>
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  )
}

export default help
